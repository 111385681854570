#login {
    height: calc(100% - 10px);
}

.logo-login {
    height: 100px;
    background-color: white;
    border-bottom: 1px solid rgb(230, 222, 222);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}

.logo-login > img {
    height: 65px;
}

.vertical-center {
    border: 1px solid rgb(230, 222, 222);
    border-radius: 5px;
    padding: 25px;
    background-color: #f8f9fa;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    width: 500px;
}

@media (max-width: 600px) {
    #login {
        margin: 20px;
    }

    .vertical-center {
        width: 100%;
    }
}

.adCard {
    border: none;
    transition: all 0.3s ease-in-out;
    border-radius: 12px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
        0 3px 1px -2px rgb(0 0 0 / 20%);
}

.adCard:hover {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
}

.adCard>img {
    border-radius: 12px 12px 0 0;
}

.modal-content {
    border: none;
    border-radius: 12px;
}

.div-info {
    justify-content: space-around;
}

.info-card {
    height: 80px;
    width: 150px;

    padding: 0px auto;
    text-align: center;
    justify-content: center;
}

@media (max-width: 600px) {
    .div-info {
        justify-content: center;
    }

    .info-card {
        margin: 0 10px 10px 10px;
        width: 150px;
        height: 50px;
        font-size: 12px;
    }
}
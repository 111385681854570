.btn-custom {
    font-size: 14px;
    display: inline-block;
    text-align: center;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 11px 0px;
    line-height: 12px;
    width: 100px;

    border: 0px;
    background-color: white;
    color: rgb(65, 65, 65);
}

.btn-custom:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.tabs-class li>button {
    color: gray;
}

.tabs-class li>button:hover {
    color: gray;
    background-color: rgb(243, 243, 243);
}

.creditCard>div>div {
    margin-top: 10px;
}
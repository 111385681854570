#cabecalho {
    border-bottom: 1px solid rgb(230, 222, 222);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    background-color: white;
}

.payment {
    background-color: #F23D4E;
    color: white;
    padding: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-button {
    background-color: #E5EDFA;
    color: #6B98DB;
    padding: 5px 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.payment-button:hover {
    background-color: #c9dcfa;
}
